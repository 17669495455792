import React from 'react'
import { useGet } from 'restful-react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { ForbiddenError, NotFoundError, InternalError } from 'components/error'
import { useAuth } from '@trileuco/triskel-react-ui/modules/auth'
import { Redirect } from '@trileuco/triskel-react-ui/components/router'
import _ from 'lodash'

export default ({ onError, ...options }) => {
  const { error, ...results } = useGet(options)

  const { msg } = useIntl({
    scope: 'backend.errors',
    ignoreGlobalScope: true
  })

  const {
    keycloak: { authenticated, userInfo }
  } = useAuth()

  if (error) {
    if (onError) {
      onError(error)
    } else {
      const errorMessage = msg({ id: error?.data?.errorCode })
      switch (error.status) {
        case 404:
          throw new NotFoundError(errorMessage)
        case 403:
          if (!authenticated && !_.isEmpty(userInfo)) {
            return <Redirect to="/" />
          } else {
            throw new ForbiddenError(errorMessage)
          }
        default:
          throw new InternalError(errorMessage)
      }
    }
  }
  return {
    error,
    ...results
  }
}
