import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/layout/Page'
import { useGetBookings } from 'components/api'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import PublicPageSkeleton from 'components/layout/pageSkeletons/PublicPageSkeleton'
import BoundedPageTemplate from 'components/layout/pageTemplates/BoundedPageTemplate'
import { IconButton } from '@trileuco/triskel-react-ui/components/ui'
import BookingTicket from 'modules/bookables/pages/bookingTickets/BookingTicket'
import BookingTicketsForm from 'modules/bookables/pages/bookingTickets/BookingTicketsForm'
import _ from 'lodash'

const BookingTicketsPage = ({ history, location }) => {
  const { msg } = useIntl({ scope: 'BookingTicketsPage' })

  const [bookingId, setBookingId] = useState('')

  const {
    bookings: [booking],
    loading,
    refetch,
    error,
    cancel
  } = useGetBookings({
    id: bookingId,
    lazy: !bookingId,
    onlyRoot: true
  })

  const handleFilterChange = useCallback(
    (bookingId) => {
      setBookingId(bookingId.trim())
      if (!_.isEmpty(bookingId)) {
        setTimeout(() => refetch, 3000)
      } else {
        cancel()
      }
    },
    [refetch, cancel]
  )

  const showBookingData = bookingId && !_.isEmpty(booking) && !error
  const noResults = !loading && !_.isEmpty(bookingId) && _.isEmpty(booking)

  return (
    <Page id="BookingTicketsPage">
      <PublicPageSkeleton
        mainContent={
          <BoundedPageTemplate
            header={
              <>
                <h1>{msg({ id: 'pageTitle' })}</h1>
                <div className="SearchWrapper">
                  <BookingTicketsForm
                    bookingId={bookingId}
                    isValidating={loading}
                    error={error}
                    onChange={handleFilterChange}
                    noResults={noResults}
                  />
                  <IconButton
                    icon="fas fa-eraser"
                    size="m"
                    variant="clear"
                    onClick={() => handleFilterChange('')}
                  />
                  {/* FIXME: clear button must be inside BookingTicketsForm */}
                </div>
              </>
            }
            mainContent={
              <>
                {showBookingData && (
                  <BookingTicket booking={booking} refetch={refetch} />
                )}
              </>
            }
          />
        }
      />
    </Page>
  )
}

BookingTicketsPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

BookingTicketsPage.displayName = 'BookingTicketsPage'

export default BookingTicketsPage
