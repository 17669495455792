import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTenantConfig } from 'modules/bookables/Provider'

const humanizeDuration = require('humanize-duration')

const Duration = (props) => {
  const {
    intl: { locale }
  } = useTenantConfig()

  const { amount, time } = props

  const durationInMs = useMemo(() => {
    let duration
    if (time) {
      // This is for articles. Reading time follows the format xx:xx
      const timeTokens = time.split(':')
      if (timeTokens.length === 3) {
        duration = +timeTokens[0] * 60 + +timeTokens[1] + +timeTokens[2] / 60
      } else {
        duration = +timeTokens[0] + +timeTokens[1] / 60
      }
    } else {
      duration = amount
    }
    return duration * 60000
  }, [amount, time])

  return (
    <Fragment>
      {humanizeDuration(durationInMs, {
        largest: 1,
        language: locale || 'es',
        fallbacks: ['es']
      })}
    </Fragment>
  )
}

Duration.propTypes = {
  amount: PropTypes.number,
  unit: PropTypes.string,
  time: PropTypes.string
}

Duration.defaultProps = {
  unit: 'minutes'
}

Duration.displayName = 'Duration'

export default Duration
