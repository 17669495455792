import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@trileuco/triskel-react-ui/components/ui'
import { useClassNames } from '@trileuco/triskel-react-ui/components/hooks'

export const ErrorInfo = ({
  code,
  title,
  description,
  buttonText,
  buttonLink,
  imgUrl,
  hiddenMessage
}) => {
  const { classNames } = useClassNames({
    alias: 'ErrorInfo'
  })
  return (
    <div className={classNames()}>
      <div className={classNames('textContent')}>
        <p className={classNames('code')}>Error {code}</p>
        <h1 className={classNames('title')}>{title}</h1>
        <p className={classNames('description')}>{description}</p>
        {buttonText && (
          <Button
            text={buttonText}
            linkTo={buttonLink}
            className={classNames('button')}
          />
        )}
      </div>
      <img src={imgUrl} alt="" className={classNames('img')} />
      <div className={classNames('hidden')}>{hiddenMessage}</div>
    </div>
  )
}

ErrorInfo.propTypes = {
  code: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  imgUrl: PropTypes.string,
  hiddenMessage: PropTypes.string
}

ErrorInfo.displayName = 'ErrorInfo'

export default ErrorInfo
