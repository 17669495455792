import React from 'react'
import PropTypes from 'prop-types'
import Price from 'components/misc/Price'

import BookablePriceDescription from './BookablePriceDescription'

const BookableGuestPrice = ({ currency, guests, pickedPriceType }) => {
  return (
    <>
      <BookablePriceDescription>
        <Price amount={pickedPriceType.amount} currency={currency} />{' '}
        {pickedPriceType.description}
      </BookablePriceDescription>

      {guests.guestTypes
        .filter(
          ({ guestType, amount }) =>
            guestType.price && guestType.price > 0 && amount > 0
        )
        .map(({ guestType }) => (
          <BookablePriceDescription key={guestType.guestTypeId}>
            <Price amount={guestType.price} currency={currency} />{' '}
            {guestType.labels.singular}
          </BookablePriceDescription>
        ))}
    </>
  )
}

BookableGuestPrice.propTypes = {
  currency: PropTypes.string,
  guests: PropTypes.object,
  pickedPriceType: PropTypes.object
}

BookableGuestPrice.displayName = 'BookableGuestPrice'

export default BookableGuestPrice
