import React from 'react'
import PropTypes from 'prop-types'
import Price from 'components/misc/Price'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'

export const AvailableTimeLabel = ({
  pickedPriceType,
  schedule,
  bookable,
  capacity
}) => {
  const { msg } = useIntl({
    scope: 'balaena.toBookPicker',
    ignoreGlobalScope: true
  })
  return (
    <div className="AvailableTimes">
      {schedule && (
        <span className="AvailableTimes_price">
          {msg(
            { id: 'pricePerGuest' },
            {
              price: (
                <span>
                  <Price
                    amount={
                      bookable.priceTypes.length > 1
                        ? pickedPriceType.price.amount
                        : schedule.priceAmount
                    }
                    currency={bookable.price.currency}
                  />
                </span>
              ),
              guest:
                bookable.priceTypes.length > 1
                  ? pickedPriceType.price.labels.singular
                  : bookable.price.labels.singular
            }
          )}
        </span>
      )}
      <span className="AvailableTimes_availability">
        {msg(
          { id: 'availability' },
          {
            remainingCapacity: capacity.remaining,
            totalCapacity: capacity.total
          }
        )}
      </span>
    </div>
  )
}
AvailableTimeLabel.propTypes = {
  bookable: PropTypes.shape(),
  pickedPriceType: PropTypes.shape(),
  schedule: PropTypes.shape(),
  capacity: PropTypes.shape()
}

AvailableTimeLabel.displayName = 'AvailableTimeLabel'
